



import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {Action, Getter} from 'vuex-class';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';
import {Unsubscribe} from 'firebase/firestore';
import {profileStore} from '@/store/modules/profile';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  name: 'home',
  components: {BaseNavigationPage}
})
export default class Home extends mixins(Navigation) {

  public unsubscribe: Unsubscribe | null = null;
  public GET_STARTED: string = 'get-started'
  public DISCOVER: string = 'discover'
  public FEATURES: string = 'features'

  @Getter public todo;

  @Action('loadSetup') public loadSetup;

  get isOwner() {
    return profileStore.isOwner
  }

  get todoCount() {
    // return this.todo.length - 1;
    return 0;
  }

  get navItems() {
    const {GET_STARTED, DISCOVER, FEATURES, isOwner, todoCount} = this
    const name = this.$route.name
    return [
      {
        title: 'Get started',
        type: GET_STARTED,
        name: GET_STARTED,
        selected: name === GET_STARTED,
        counter: isOwner ? todoCount : 0,
        badge: true
      },
      {
        title: 'Discover Pigeon',
        type: DISCOVER,
        name: DISCOVER,
        selected: name === DISCOVER
      },
      {
        title: 'Latest features',
        type: FEATURES,
        name: FEATURES,
        selected: name === FEATURES
      }
    ];
  }

  public onNavItemSelected(navItem) {
    if (navItem.name === 'discover') {
      window.open('https://pigeonplatform.notion.site/pigeonplatform/Pigeon-Platform-Help-Center-aa58fa25b2d148348bafa1cc73839a1c', '_blank')
      return
    }
    this.$router.push({name: navItem.name, params: {}});
  }

  public async created() {
    this.selectPage(1);
  }

  public beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  public beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.unsubscribe = await vm.loadSetup();
    })
  }
}
